<template>
  <div class="container">
    <h2>Mis Médicos</h2>
    <div class="doctorCard" v-for="(doctor, i) in doctors" :key="'doctor' + i">
      <vs-card class="drCard" type="3">
        <template #title>
          <h3>Dr. {{ doctor.displayName }}</h3>
        </template>
        <template #img>
          <img
            :src="
              doctor.profileImg ||
                'https://image.freepik.com/vector-gratis/doctor-icono-o-avatar-blanco_136162-58.jpg'
            "
            alt=""
          />
        </template>
        <template #text>
          <div
            v-for="(intervention, ind) in doctor.interventions"
            :key="'interv_' + ind"
          >
            <b>{{ intervention.name || "Operación test" }} </b>
            <em>{{ "(" + intervention.surgeryDate + ")" }}</em>
          </div>
        </template>
      </vs-card>
    </div>
  </div>
</template>

<script>
import { patientMix, protocolMix, doctorMix } from "@/mixins/mixins.js";

export default {
  name: "DoctorsPage",
  mixins: [patientMix, protocolMix, doctorMix],
  data() {
    return {
      editActive: false,
      edit: null,
      editProp: {},
      groups: [],
      doctors: [],
      test: [],
    };
  },
  methods: {
    async initData(uid) {
      const drs = await this.getDoctors(uid);
      await drs.forEach(async (dr) => {
        const protocols = await this.getProtocols(dr.id);
        const interventions = [];
        protocols.forEach(async (protoc) => {
          const result = await this.getFilteredInterventions(uid, protoc.id);
          if (result.length > 0) {
            result.forEach((r) => {
              interventions.push(r);
            });
          }
        });
        dr.interventions = interventions;
        this.doctors.push(dr);
      });
      // this.doctors = new Array(drs).flat();
    },
  },
  created() {
    const { uid } = this.$store.state.currentUserInfo;
    this.initData(uid);
  },
};
</script>

<style scoped>
.container {
  text-align: justify;
  padding: 4rem 1rem 0rem 4.5rem;
}

.userActions,
.userData {
  display: flex;
}

.userDataItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  text-align: start;
}

.doctorCard {
  box-shadow: 9px 10px 20px 6px #7797b7c7;
  border-radius: 2rem;
  margin-bottom: 1rem;
}
</style>
<style>
.doctorCard > div > div > .vs-card__img {
  width: 35%;
  /* min-width: 200px; */
  max-height: 200px;
  object-fit: cover;
  border: 0.25rem solid #17a2b8;
  border-bottom: none;
  border-top: none;
  border-left: none;
}

.drCard > .vs-card {
  max-width: none !important;
}
</style>
